<template>
	<WDialog :value="value" error max-width="600px" no-padding :title="$t('sa.import.errors_report')" @input="closeDialog">
		<v-layout column>
			<v-flex shrink>
				<v-tabs v-model="tabs" slider-color="primary">
					<v-tab>
						{{ $t('sa.import.sections.third') }}
					</v-tab>
					<v-tab> {{ $t('sa.import.sections.fifth') }}</v-tab>
				</v-tabs>
			</v-flex>
			<v-flex px-3 scroll-y>
				<v-tabs-items v-model="tabs">
					<v-tab-item :key="0">
						<ul v-if="errors.customers && errors.customers.length > 0">
							<li v-for="(error, index) in errors.customers" :key="index" v-text="error" />
						</ul>
						<v-alert v-else :value="true" type="success">
							{{ $t('sa.import.no_error') }}
						</v-alert>
					</v-tab-item>
					<v-tab-item :key="1">
						<ul v-if="errors.accountingFirmUsers && errors.accountingFirmUsers.length > 0">
							<li v-for="(error, index) in errors.accountingFirmUsers" :key="index" v-text="error" />
						</ul>
						<v-alert v-else :value="true" type="success">
							{{ $t('sa.import.no_error') }}
						</v-alert>
					</v-tab-item>
				</v-tabs-items>
			</v-flex>
		</v-layout>
	</WDialog>
</template>

<script>
export default {
	name: 'ErrorsList',
	props: {
		errors: {
			required: true,
			type: Object
		},
		value: {
			required: true,
			type: Boolean
		}
	},
	data: function () {
		return {
			tabs: 0
		}
	},
	methods: {
		closeDialog: function () {
			this.$emit('input', false)
		}
	}
}
</script>
